// Grid.tsx - Responsive Grid Component
import React, { useEffect, useState, useRef } from 'react'
import { CompletedRow } from './CompletedRow'
import { CurrentRow } from './CurrentRow'
import { EmptyRow } from './EmptyRow'

interface GridProps {
  guesses: string[]
  currentGuess: string
  isArabicKeyboard?: boolean
  solution: string
  isRevealing: boolean
}

export const Grid: React.FC<GridProps> = ({
  guesses,
  currentGuess,
  isArabicKeyboard = false,
  solution,
  isRevealing,
}) => {
  const maxTries = Number(process.env.REACT_APP_LENGTH_OF_LINES) || 6
  const empties =
    guesses.length < maxTries - 1
      ? Array.from(Array(maxTries - 1 - guesses.length))
      : []

  // Track container dimensions
  const gridContainerRef = useRef<HTMLDivElement>(null)
  const [cellSize, setCellSize] = useState({
    width: '32px',
    height: '32px',
    fontSize: '16px',
    gap: '0.25rem',
  })

  // Update dimensions on resize and initialization
  useEffect(() => {
    const updateCellSize = () => {
      if (!gridContainerRef.current) return

      const container = gridContainerRef.current
      const containerWidth = container.clientWidth
      const containerHeight = container.clientHeight

      // Calculate available space
      const maxWidth = containerWidth * 0.85 // Use 85% of container width
      const maxHeight = containerHeight * 0.9 // Use 90% of container height

      // Calculate cell size based on available space
      // We need 5 cells horizontally with gaps
      const horizontalGap = 8 // 4px gap × 2 sides
      const verticalGap = 8 // 4px gap × 2 sides

      const maxCellWidth = Math.floor(maxWidth / 5) - horizontalGap
      const maxCellHeight = Math.floor(maxHeight / 6) - verticalGap

      // Use the smaller dimension to maintain square cells
      const size = Math.min(maxCellWidth, maxCellHeight, 50) // Cap at 50px

      // Calculate appropriate font size (50% of cell size)
      const fontSize = Math.max(Math.floor(size * 0.5), 12)

      // Calculate appropriate gap size (proportional to cell size)
      const gap = Math.max(Math.floor(size * 0.1), 2) + 'px'

      setCellSize({
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${fontSize}px`,
        gap,
      })
    }

    // Initial calculation
    updateCellSize()

    // Attach resize listener
    window.addEventListener('resize', updateCellSize)

    // Cleanup
    return () => window.removeEventListener('resize', updateCellSize)
  }, [])

  return (
    <div
      ref={gridContainerRef}
      className="grid-container w-full h-full flex items-center justify-center px-1"
    >
      <div className="grid-content">
        <div className="grid" style={{ gap: cellSize.gap }}>
          {guesses.map((guess, i) => (
            <div key={i} className="w-full flex justify-center">
              <CompletedRow
                guess={guess}
                isArabicKeyboard={isArabicKeyboard}
                solution={solution}
                isRevealing={isRevealing && i === guesses.length - 1}
                cellSize={cellSize}
              />
            </div>
          ))}

          {guesses.length < maxTries && (
            <div className="w-full flex justify-center">
              <CurrentRow
                guess={currentGuess}
                isArabicKeyboard={isArabicKeyboard}
                cellSize={cellSize}
              />
            </div>
          )}

          {empties.map((_, i) => (
            <div key={i} className="w-full flex justify-center opacity-70">
              <EmptyRow cellSize={cellSize} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Grid
