// Modified WinModal.tsx with Twitter/X-specific sharing

import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XIcon, XCircleIcon } from '@heroicons/react/outline'
import { MiniGrid } from '../mini-grid/MiniGrid'
import {
  shareStatus,
  shareMobileStatus,
  shareToTwitter,
  shareToWhatsApp,
} from '../../helpers/share'
import { detectMob } from '../../helpers/device'

// Social Media Icons
const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-3 w-3 sm:h-4 sm:w-4"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </svg>
)

const WhatsAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-3 w-3 sm:h-4 sm:w-4"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z" />
  </svg>
)

// Cascading celebration animation that drops from top to bottom
function triggerCascadeCelebration() {
  // Create container for all celebration elements
  const container = document.createElement('div')
  container.style.position = 'fixed'
  container.style.top = '0'
  container.style.left = '0'
  container.style.width = '100%'
  container.style.height = '100%'
  container.style.pointerEvents = 'none'
  container.style.zIndex = '9999'
  container.style.overflow = 'hidden'
  document.body.appendChild(container)

  // Create and add the animation style
  const style = document.createElement('style')
  style.textContent = `
    @keyframes cascade-drop {
      0% {
        transform: translateY(-100%);
        opacity: 0;
      }
      10% {
        opacity: 1;
      }
      80% {
        opacity: 1;
      }
      100% {
        transform: translateY(100vh);
        opacity: 0;
      }
    }
    
    .celebration-particle {
      position: absolute;
      animation: cascade-drop 3s ease-in-out forwards;
      top: 0;
    }
  `
  document.head.appendChild(style)

  // Celebration elements to use
  const celebrationItems = [
    '🎉',
    '🎊',
    '🏆',
    '🌟',
    '✨',
    '💫',
    '⭐',
    '🥇',
    '🎯',
    '🎖️',
  ]

  // Create particles
  const createParticles = () => {
    const count = detectMob() ? 30 : 60

    for (let i = 0; i < count; i++) {
      // Create particle
      const particle = document.createElement('div')
      particle.className = 'celebration-particle'

      // Random item from celebration elements
      const item =
        celebrationItems[Math.floor(Math.random() * celebrationItems.length)]
      particle.textContent = item

      // Random position, size and delay
      const left = Math.random() * 100
      const size = Math.random() * 20 + 15
      const delay = Math.random() * 1.5

      // Apply styles
      particle.style.left = `${left}%`
      particle.style.fontSize = `${size}px`
      particle.style.animationDelay = `${delay}s`

      // Add to container
      container.appendChild(particle)
    }
  }

  // Execute animation
  createParticles()

  // Clean up after animation
  setTimeout(() => {
    document.body.removeChild(container)
    document.head.removeChild(style)
  }, 4500) // 3s animation + 1.5s max delay
}

// Simplified StatBox for mobile - much more compact
const StatBox = ({
  value,
  label,
  isHighlighted = false,
}: {
  value: string | number
  label: string
  isHighlighted?: boolean
}) => (
  <div
    className={`
    ${
      isHighlighted
        ? 'bg-blue-50 border border-blue-200'
        : 'bg-gray-50 border border-gray-100'
    }
    p-0.5 sm:p-2 rounded text-center
  `}
  >
    <div
      className={`
      text-sm sm:text-lg font-bold 
      ${isHighlighted ? 'text-blue-600' : 'text-gray-900'}
    `}
    >
      {value}
    </div>
    <div className="text-[8px] sm:text-xs text-gray-500">{label}</div>
  </div>
)

// Ultra-compact distribution display for mobile
const DistributionBar = ({
  isArabic,
  distribution,
  guesses,
  isLost,
  isMobile,
}: {
  isArabic: boolean
  distribution: number[]
  guesses: string[]
  isLost: boolean
  isMobile: boolean
}) => {
  const maxValue = Math.max(...distribution, 1)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setAnimate(true), 400)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="mt-1 sm:mt-2">
      <h3 className="text-[8px] sm:text-xs font-medium text-gray-500 mb-0.5">
        {isArabic ? 'توزيع التخمينات' : 'GUESS DISTRIBUTION'}
      </h3>
      <div className="space-y-0.5">
        {/* Always show at least 3 rows, even if distribution is empty */}
        {distribution.length > 0
          ? distribution.map((count, i) => {
              // On mobile, we only show 3 rows max to save space
              if (isMobile && i > 2 && count === 0 && guesses.length !== i + 1)
                return null

              const isCurrentGuess = guesses.length === i + 1 && !isLost
              const barWidth = animate
                ? `${Math.max((count / maxValue) * 100, 15)}%`
                : '0%'

              return (
                <div
                  key={i}
                  className="flex items-center text-[8px] sm:text-xs"
                >
                  <div className="w-2 sm:w-4">{i + 1}</div>
                  <div className="flex-1 ml-0.5 sm:ml-1">
                    <div
                      className={`
                      h-3 sm:h-6 flex items-center justify-end px-1 rounded
                      transition-all duration-500 ease-out
                      ${
                        isCurrentGuess
                          ? 'bg-green-500 text-white font-bold'
                          : 'bg-gray-300'
                      }
                    `}
                      style={{ width: barWidth }}
                    >
                      <span className="text-[8px] sm:text-xs font-medium text-white">
                        {count}
                      </span>
                    </div>
                  </div>
                </div>
              )
            })
          : // Fallback if distribution is empty
            [0, 1, 2].map((i) => (
              <div key={i} className="flex items-center text-[8px] sm:text-xs">
                <div className="w-2 sm:w-4">{i + 1}</div>
                <div className="flex-1 ml-0.5 sm:ml-1">
                  <div
                    className="h-3 sm:h-6 flex items-center justify-end px-1 rounded bg-gray-300"
                    style={{ width: '15%' }}
                  >
                    <span className="text-[8px] sm:text-xs font-medium text-white">
                      0
                    </span>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  )
}

type Props = {
  isOpen: boolean
  handleClose: () => void
  guesses: string[]
  handleShare: () => void
  isLost?: boolean
  timeRested: string | null
  solution: string
  stats: {
    gamesPlayed: number
    winRate: number
    currentStreak: number
    bestStreak: number
    distribution: number[]
  }
}

export const WinModal = ({
  isOpen,
  handleClose,
  guesses,
  handleShare,
  isLost = false,
  timeRested = null,
  solution,
  stats,
}: Props) => {
  const isArabic = process.env.REACT_APP_LANG === 'Arabic'
  const isMobile = detectMob()
  const [isVerySmallScreen, setIsVerySmallScreen] = useState(false)

  // Measure viewport dimensions
  useEffect(() => {
    const checkScreenSize = () => {
      setIsVerySmallScreen(window.innerWidth < 360 || window.innerHeight < 650)
    }

    checkScreenSize()
    window.addEventListener('resize', checkScreenSize)

    return () => window.removeEventListener('resize', checkScreenSize)
  }, [])

  // Trigger celebration animation when the modal opens after winning
  useEffect(() => {
    if (isOpen && !isLost) {
      triggerCascadeCelebration()
    }
  }, [isOpen, isLost])

  // Find the highlighted stat
  const getHighlightedStat = () => {
    if (stats.currentStreak > 3) return 'currentStreak'
    if (stats.winRate > 75) return 'winRate'
    if (guesses.length === 1) return 'guessCount'
    return ''
  }

  const highlightedStat = getHighlightedStat()
  const [timeLeft, setTimeLeft] = useState(timeRested || '')

  // Update the countdown timer
  useEffect(() => {
    if (!timeRested) return

    setTimeLeft(timeRested)
    const timer = setInterval(() => {
      setTimeLeft(timeRested)
    }, 1000)

    return () => clearInterval(timer)
  }, [timeRested])

  // Social sharing handlers
  const handleTwitterShare = () => {
    shareToTwitter(guesses, solution, isLost)
  }

  const handleWhatsAppShare = () => {
    shareToWhatsApp(guesses, solution, isLost)
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-hidden" // Prevent scrolling
        onClose={handleClose}
      >
        <div className="flex items-center justify-center min-h-screen p-2 sm:p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`
                relative inline-block bg-white rounded-xl px-2 sm:px-4 pt-2 pb-2 sm:py-4
                text-left transform transition-all 
                sm:my-8 sm:align-middle sm:max-w-sm w-full max-w-[95vw] sm:p-6 
                border border-gray-100
                overflow-visible
              `}
            >
              {/* Close button - positioned outside on mobile for more space */}
              <button
                onClick={handleClose}
                className="absolute -right-1 -top-1 sm:right-2 sm:top-2 
                  p-1 rounded-full bg-white shadow-md hover:bg-gray-100 transition-colors
                  focus:outline-none focus:ring-2 focus:ring-gray-400 z-10"
                aria-label="Close"
              >
                <XCircleIcon className="h-4 w-4 sm:h-5 sm:w-5 text-gray-500" />
              </button>

              {/* Game content - ultra compact for mobile */}
              <div className="text-center space-y-1.5 sm:space-y-3">
                {/* Top header with icon and title combined */}
                <div className="flex-col items-center justify-center space-x-2">
                  <div
                    className={`
                      w-6 h-6 sm:w-10 sm:h-10 rounded-full flex items-center justify-center
                      ${!isLost ? 'bg-green-100' : 'bg-red-100'}
                      ${!isLost ? 'animate-pulse-gentle' : ''}
                    `}
                  >
                    {!isLost ? (
                      <CheckIcon className="w-4 h-4 sm:w-6 sm:h-6 text-green-600" />
                    ) : (
                      <XIcon className="w-4 h-4 sm:w-6 sm:h-6 text-red-600" />
                    )}
                  </div>

                  <div>
                    <h3 className="text-sm sm:text-lg font-bold text-gray-900 mb-0">
                      {!isLost
                        ? isArabic
                          ? '! تخمين صحيح'
                          : 'Excellent!'
                        : isArabic
                        ? '! تخمين خاطئ'
                        : 'Game Over!'}
                    </h3>
                    <span
                      className={`
                        text-[10px] sm:text-sm ${
                          !isLost
                            ? 'text-green-600 font-medium'
                            : 'text-red-600 font-medium'
                        }
                      `}
                    >
                      {isArabic
                        ? `${solution + ' هي كلمة اليوم'}`
                        : `"${solution}"`}
                    </span>
                  </div>
                </div>

                {/* Mini Grid - using very small cells on mobile */}
                <div className="sm:bg-gray-50 sm:rounded-lg p-2 sm:shadow-inner">
                  <MiniGrid
                    guesses={guesses}
                    solution={solution}
                    isArabicKeyboard={isArabic}
                    noPadding
                    small={true} // Always use small cells on mobile
                  />
                </div>

                {/* Stats Grid - 4 cols for ultra compact layout */}
                <h3 className="text-[8px] sm:text-xs font-medium text-gray-500 mb-0.5">
                  {isArabic ? 'احصائيات' : 'Stats'}
                </h3>
                <div className="grid grid-cols-4 gap-0.5 sm:gap-2 pb-2">
                  <StatBox
                    value={stats.gamesPlayed}
                    label={isArabic ? 'لعب' : 'Played'}
                  />
                  <StatBox
                    value={`${stats.winRate}%`}
                    label={isArabic ? 'فوز' : 'Win'}
                    isHighlighted={highlightedStat === 'winRate'}
                  />
                  <StatBox
                    value={stats.currentStreak}
                    label={isArabic ? 'سلسلة' : 'Streak'}
                    isHighlighted={highlightedStat === 'currentStreak'}
                  />
                  <StatBox
                    value={stats.bestStreak}
                    label={isArabic ? 'أفضل' : 'Best'}
                  />
                </div>

                {/* Distribution - now always visible */}
                <div className="pb-2">
                  <DistributionBar
                    isArabic={isArabic}
                    distribution={stats.distribution}
                    guesses={guesses}
                    isLost={isLost}
                    isMobile={isMobile}
                  />
                </div>

                {/* Timer - simplified on mobile */}
                {timeLeft && !isVerySmallScreen && (
                  <div className="pb-1">
                    <div className="mt-1 mb-1">
                      <div className="py-0.5 px-2 bg-gray-100 rounded-lg border border-gray-200">
                        <div className="text-xs sm:text-sm font-mono font-bold tabular-nums">
                          {isArabic ? 'الكلمة التالية: ' : 'NEXT: '}
                          {timeLeft}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Redesigned sharing section with friendlier UI */}
                <div className="w-full text-center mb-2 overflow-hidden rounded-lg shadow-md">
                  {/* Header with friendly color and clear instruction */}
                  <div className="px-3 py-3 bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
                    <h4 className="text-sm sm:text-base font-bold mb-1">
                      {isArabic ? 'تحدى أصدقاءك' : 'Challenge your friends'}
                    </h4>
                    <p className="text-xs text-blue-100">
                      {isArabic
                        ? 'شارك نتيجتك مع الأصدقاء'
                        : 'Share your result with friends'}
                    </p>
                  </div>

                  {/* Buttons with friendly colors on clean background */}
                  <div className="bg-white p-3">
                    {/* Desktop: Twitter button only with friendly styling */}
                    {!isMobile && (
                      <button
                        onClick={handleTwitterShare}
                        className="w-full flex items-center justify-center py-3 px-4
                          bg-black text-white font-medium rounded-md
                          transition-all duration-200 shadow-sm"
                      >
                        <TwitterIcon />
                        <span className="ml-2">
                          {isArabic
                            ? 'مشاركة على إكس (تويتر)'
                            : 'Share on X (Twitter)'}
                        </span>
                      </button>
                    )}

                    {/* Mobile: Social Media Share Grid with friendly styling */}
                    {isMobile && (
                      <div className="grid grid-cols-2 gap-3">
                        {/* Twitter Share Button */}
                        <button
                          onClick={handleTwitterShare}
                          className="flex items-center justify-center py-3
                            bg-black text-white font-medium rounded-md
                            transition-all duration-200 shadow-sm"
                        >
                          <TwitterIcon />
                          <span className="ml-2">{isArabic ? 'إكس' : 'X'}</span>
                        </button>

                        {/* WhatsApp Share Button */}
                        <button
                          onClick={handleWhatsAppShare}
                          className="flex items-center justify-center py-3
                            bg-green-500 hover:bg-green-600 text-white font-medium rounded-md
                            transition-all duration-200 shadow-sm"
                        >
                          <WhatsAppIcon />
                          <span className="ml-2">
                            {isArabic ? 'واتساب' : 'WhatsApp'}
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
