const gameStateKey = 'gameState'
const lastDateKey = 'lastDate'
const nextDayDateKey = 'newtDayDate'
const startDateKey = 'startDate'
const STATS_KEY = 'gameStats'

type StoredGameState = {
  guesses: string[]
  solution: string
}

export type GameStats = {
  gamesPlayed: number
  winRate: number
  currentStreak: number
  bestStreak: number
  distribution: number[]
}

export const saveGameStateToLocalStorage = (gameState: StoredGameState) => {
  localStorage.setItem(gameStateKey, JSON.stringify(gameState))
}

export const loadGameStateFromLocalStorage = () => {
  const state = localStorage.getItem(gameStateKey)

  return state ? (JSON.parse(state) as StoredGameState) : null
}

export const clearGameStateToLocalStorage = () => {
  localStorage.removeItem(gameStateKey)
}

// Last date
export const saveLastDateToLocalStorage = (lastDate: number) => {
  localStorage.setItem(lastDateKey, JSON.stringify(lastDate))
}

export const clearLastDateToLocalStorage = () => {
  localStorage.removeItem(lastDateKey)
}

export const loadLastDateFromLocalStorage = () => {
  const lastDate = localStorage.getItem(lastDateKey)

  return lastDate ? (JSON.parse(lastDate) as number) : null
}

// Next day date
export const saveNextDayDateToLocalStorage = (nextDayDate: number) => {
  localStorage.setItem(nextDayDateKey, JSON.stringify(nextDayDate))
}

export const clearNextDayDateToLocalStorage = () => {
  localStorage.removeItem(nextDayDateKey)
}

export const loadNextDayDateFromLocalStorage = () => {
  const nextDayDate = localStorage.getItem(nextDayDateKey)

  return nextDayDate ? (JSON.parse(nextDayDate) as number) : null
}

// Start date
export const saveStartDateToLocalStorage = (startDate: Date) => {
  localStorage.setItem(startDateKey, JSON.stringify(startDate))
}

export const clearStartDateToLocalStorage = () => {
  localStorage.removeItem(startDateKey)
}

export const loadStartDateDateFromLocalStorage = () => {
  const startDate = localStorage.getItem(startDateKey)

  return startDate ? (JSON.parse(startDate) as Date) : null
}

// Stats
export const loadStats = () => {
  const stats = window.localStorage.getItem(STATS_KEY)
  return stats
    ? (JSON.parse(stats) as GameStats)
    : {
        gamesPlayed: 0,
        winRate: 0,
        currentStreak: 0,
        bestStreak: 0,
        distribution: [0, 0, 0, 0, 0, 0],
      }
}

export const saveStats = (stats: GameStats) => {
  window.localStorage.setItem(STATS_KEY, JSON.stringify(stats))
}

export const addStatsForCompletedGame = (
  stats: GameStats,
  count: number,
  won: boolean
) => {
  const newStats = { ...stats }

  newStats.gamesPlayed += 1

  if (won) {
    newStats.currentStreak += 1
    newStats.bestStreak = Math.max(newStats.bestStreak, newStats.currentStreak)
    newStats.distribution[count] += 1
  } else {
    newStats.currentStreak = 0
  }

  newStats.winRate = Math.round(
    (newStats.distribution.reduce((a, b) => a + b, 0) / newStats.gamesPlayed) *
      100
  )

  return newStats
}
