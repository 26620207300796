// Keyboard.tsx - Responsive Keyboard Component with consistent layout
import { BackspaceIcon } from '@heroicons/react/outline'
import { KeyValue } from '../../helpers/keyboard'
import { getStatuses, CharStatus } from '../../helpers/statuses'
import { Key } from './Key'
import { useEffect, useState, useRef } from 'react'

type Props = {
  onChar: (value: string) => void
  onDelete: () => void
  onEnter: () => void
  guesses: string[]
  isArabicKeyboard?: boolean
  solution: string
  isRevealing?: boolean
}

type KeyboardArabicProps = {
  onClick: (value: KeyValue) => void
  charStatuses: { [key: string]: CharStatus }
}

function isArabic(text: string) {
  const arabic = /[\u0600-\u06FF]/
  const result = arabic.test(text)
  return result
}

const KeyboardArabic = ({ onClick, charStatuses }: KeyboardArabicProps) => {
  // Keep keyboardRef for potential future use but remove layout switching logic
  const keyboardRef = useRef<HTMLDivElement>(null)

  return (
    <div ref={keyboardRef} className="p-3 keyboard-container">
      {/* First row - 12 keys */}
      <div className="flex justify-center mb-0.5">
        <Key value="ض" onClick={onClick} status={charStatuses['ض']} />
        <Key value="ص" onClick={onClick} status={charStatuses['ص']} />
        <Key value="ث" onClick={onClick} status={charStatuses['ث']} />
        <Key value="ق" onClick={onClick} status={charStatuses['ق']} />
        <Key value="ف" onClick={onClick} status={charStatuses['ف']} />
        <Key value="غ" onClick={onClick} status={charStatuses['غ']} />
        <Key value="ع" onClick={onClick} status={charStatuses['ع']} />
        <Key value="ه" onClick={onClick} status={charStatuses['ه']} />
        <Key value="خ" onClick={onClick} status={charStatuses['خ']} />
        <Key value="ح" onClick={onClick} status={charStatuses['ح']} />
        <Key value="ج" onClick={onClick} status={charStatuses['ج']} />
        <Key value="د" onClick={onClick} status={charStatuses['د']} />
      </div>

      {/* Second row - 12 keys */}
      <div className="flex justify-center mb-0.5">
        <Key value="ش" onClick={onClick} status={charStatuses['ش']} />
        <Key value="س" onClick={onClick} status={charStatuses['س']} />
        <Key value="ي" onClick={onClick} status={charStatuses['ي']} />
        <Key value="ب" onClick={onClick} status={charStatuses['ب']} />
        <Key value="ل" onClick={onClick} status={charStatuses['ل']} />
        <Key value="ا" onClick={onClick} status={charStatuses['ا']} />
        <Key value="ت" onClick={onClick} status={charStatuses['ت']} />
        <Key value="ن" onClick={onClick} status={charStatuses['ن']} />
        <Key value="م" onClick={onClick} status={charStatuses['م']} />
        <Key value="ك" onClick={onClick} status={charStatuses['ك']} />
        <Key value="ط" onClick={onClick} status={charStatuses['ط']} />
        <Key value="ذ" onClick={onClick} status={charStatuses['ذ']} />
      </div>

      {/* Third row - 10 keys including Delete */}
      <div className="flex justify-center mb-0.5">
        <Key value="ئ" onClick={onClick} status={charStatuses['ئ']} />
        <Key value="ء" onClick={onClick} status={charStatuses['ء']} />
        <Key value="ؤ" onClick={onClick} status={charStatuses['ؤ']} />
        <Key value="ر" onClick={onClick} status={charStatuses['ر']} />
        <Key value="ى" onClick={onClick} status={charStatuses['ى']} />
        <Key value="ة" onClick={onClick} status={charStatuses['ة']} />
        <Key value="و" onClick={onClick} status={charStatuses['و']} />
        <Key value="ز" onClick={onClick} status={charStatuses['ز']} />
        <Key value="ظ" onClick={onClick} status={charStatuses['ظ']} />
        <Key value="DELETE" onClick={onClick} bgColor={'#e11d48'}>
          <BackspaceIcon className="h-5 w-5 cursor-pointer" />
        </Key>
      </div>

      {/* Bottom row - Enter key centered */}
      <div className="btnEnter flex justify-center mt-1">
        <Key value="ENTER" onClick={onClick} bgColor={'#0284c7'}>
          Enter
        </Key>
      </div>
    </div>
  )
}

export const Keyboard = ({
  onChar,
  onDelete,
  onEnter,
  guesses,
  isArabicKeyboard = false,
  solution,
  isRevealing = false,
}: Props) => {
  const [statuses, setStatuses] = useState<{ [key: string]: CharStatus }>({})
  const [keyPressAnimation, setKeyPressAnimation] = useState<string | null>(
    null
  )

  useEffect(() => {
    if (isRevealing) {
      // Wait for the entire grid animation to complete
      const updateTimer = setTimeout(() => {
        setStatuses(getStatuses(guesses, solution))
      }, 2400) // Wait for full grid animation

      return () => clearTimeout(updateTimer)
    } else if (!isRevealing && guesses.length > 0) {
      // Update immediately if not revealing
      setStatuses(getStatuses(guesses, solution))
    }
  }, [guesses, solution, isRevealing])

  const onClick = (value: KeyValue) => {
    // Add visual feedback for key press
    setKeyPressAnimation(value.toString())
    setTimeout(() => setKeyPressAnimation(null), 200)

    if (value === 'ENTER') {
      onEnter()
    } else if (value === 'DELETE') {
      onDelete()
    } else {
      if (isArabicKeyboard) {
        if (isArabic(value)) {
          onChar(value)
        }
      } else {
        onChar(value)
      }
    }
  }

  // Use Arabic keyboard if needed
  if (isArabicKeyboard) {
    return (
      <div className="keyboard-wrapper">
        <KeyboardArabic onClick={onClick} charStatuses={statuses} />
      </div>
    )
  }

  // English keyboard layout - with responsive tweaks
  return (
    <div className="keyboard-wrapper relative">
      <div className="p-3 keyboard-container relative bg-gradient-to-b from-gray-800/80 to-gray-900/90 rounded-xl border border-gray-700/30 shadow-xl">
        <div className="flex justify-center mb-0.5">
          <Key
            value="Q"
            onClick={onClick}
            status={statuses['Q']}
            isPressed={keyPressAnimation === 'Q'}
          />
          <Key
            value="W"
            onClick={onClick}
            status={statuses['W']}
            isPressed={keyPressAnimation === 'W'}
          />
          <Key
            value="E"
            onClick={onClick}
            status={statuses['E']}
            isPressed={keyPressAnimation === 'E'}
          />
          <Key
            value="R"
            onClick={onClick}
            status={statuses['R']}
            isPressed={keyPressAnimation === 'R'}
          />
          <Key
            value="T"
            onClick={onClick}
            status={statuses['T']}
            isPressed={keyPressAnimation === 'T'}
          />
          <Key
            value="Y"
            onClick={onClick}
            status={statuses['Y']}
            isPressed={keyPressAnimation === 'Y'}
          />
          <Key
            value="U"
            onClick={onClick}
            status={statuses['U']}
            isPressed={keyPressAnimation === 'U'}
          />
          <Key
            value="I"
            onClick={onClick}
            status={statuses['I']}
            isPressed={keyPressAnimation === 'I'}
          />
          <Key
            value="O"
            onClick={onClick}
            status={statuses['O']}
            isPressed={keyPressAnimation === 'O'}
          />
          <Key
            value="P"
            onClick={onClick}
            status={statuses['P']}
            isPressed={keyPressAnimation === 'P'}
          />
        </div>
        <div className="flex justify-center mb-0.5">
          <Key
            value="A"
            onClick={onClick}
            status={statuses['A']}
            isPressed={keyPressAnimation === 'A'}
          />
          <Key
            value="S"
            onClick={onClick}
            status={statuses['S']}
            isPressed={keyPressAnimation === 'S'}
          />
          <Key
            value="D"
            onClick={onClick}
            status={statuses['D']}
            isPressed={keyPressAnimation === 'D'}
          />
          <Key
            value="F"
            onClick={onClick}
            status={statuses['F']}
            isPressed={keyPressAnimation === 'F'}
          />
          <Key
            value="G"
            onClick={onClick}
            status={statuses['G']}
            isPressed={keyPressAnimation === 'G'}
          />
          <Key
            value="H"
            onClick={onClick}
            status={statuses['H']}
            isPressed={keyPressAnimation === 'H'}
          />
          <Key
            value="J"
            onClick={onClick}
            status={statuses['J']}
            isPressed={keyPressAnimation === 'J'}
          />
          <Key
            value="K"
            onClick={onClick}
            status={statuses['K']}
            isPressed={keyPressAnimation === 'K'}
          />
          <Key
            value="L"
            onClick={onClick}
            status={statuses['L']}
            isPressed={keyPressAnimation === 'L'}
          />
        </div>
        <div className="flex justify-center">
          <Key
            value="ENTER"
            onClick={onClick}
            isPressed={keyPressAnimation === 'ENTER'}
            bgColor={'#0284c7'}
          >
            Enter
          </Key>
          <Key
            value="Z"
            onClick={onClick}
            status={statuses['Z']}
            isPressed={keyPressAnimation === 'Z'}
          />
          <Key
            value="X"
            onClick={onClick}
            status={statuses['X']}
            isPressed={keyPressAnimation === 'X'}
          />
          <Key
            value="C"
            onClick={onClick}
            status={statuses['C']}
            isPressed={keyPressAnimation === 'C'}
          />
          <Key
            value="V"
            onClick={onClick}
            status={statuses['V']}
            isPressed={keyPressAnimation === 'V'}
          />
          <Key
            value="B"
            onClick={onClick}
            status={statuses['B']}
            isPressed={keyPressAnimation === 'B'}
          />
          <Key
            value="N"
            onClick={onClick}
            status={statuses['N']}
            isPressed={keyPressAnimation === 'N'}
          />
          <Key
            value="M"
            onClick={onClick}
            status={statuses['M']}
            isPressed={keyPressAnimation === 'M'}
          />
          <Key
            value="DELETE"
            onClick={onClick}
            isPressed={keyPressAnimation === 'DELETE'}
            bgColor={'#e11d48'}
          >
            <BackspaceIcon className="h-5 w-5" />
          </Key>
        </div>

        {/* Simplified reflection effect */}
        <div className="absolute inset-x-0 bottom-0 h-1/4 bg-gradient-to-t from-white/5 to-transparent rounded-b-xl pointer-events-none"></div>
      </div>
    </div>
  )
}

export default Keyboard
