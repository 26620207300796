// src/helpers/hotjar.ts - Simple Hotjar initialization without custom events

/**
 * Initialize Hotjar tracking.
 *
 * @param hjid Your Hotjar Site ID
 * @param hjsv Hotjar Script Version (typically 6)
 */
export const initHotjar = (hjid: number, hjsv: number): void => {
  // Add Hotjar tracking code
  ;(function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
    h.hj =
      h.hj ||
      function () {
        ;(h.hj.q = h.hj.q || []).push(arguments)
      }
    h._hjSettings = { hjid, hjsv }
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
    a.appendChild(r)
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
}
