import React, { useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import {
  XCircleIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  CheckIcon,
  LightBulbIcon,
} from '@heroicons/react/outline'
import { Cell } from '../grid/Cell'
import { CharStatus } from '../../helpers/statuses'

// LocalStorage key for tracking tutorial state
const TUTORIAL_SHOWN_KEY = 'tutorialShown'

interface OnboardingTutorialProps {
  isArabic?: boolean
}

const OnboardingTutorial: React.FC<OnboardingTutorialProps> = ({
  isArabic = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [animate, setAnimate] = useState(false)

  // Check if user has seen the tutorial before
  useEffect(() => {
    const tutorialShown = localStorage.getItem(TUTORIAL_SHOWN_KEY)
    if (!tutorialShown) {
      // Show the tutorial after a slight delay to allow the page to load
      setTimeout(() => {
        setIsOpen(true)
        setAnimate(true)
      }, 1500)
    }
  }, [])

  // Trigger animation when component mounts or changes step
  useEffect(() => {
    if (isOpen) {
      // Set initial animation state
      setAnimate(true)
    }
  }, [isOpen, currentStep])

  // Handle closing the tutorial
  const handleClose = () => {
    localStorage.setItem(TUTORIAL_SHOWN_KEY, 'true')
    setIsOpen(false)
  }

  // Move to the next step
  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setAnimate(false)
      setTimeout(() => {
        setCurrentStep(currentStep + 1)
        setAnimate(true)
      }, 300)
    } else {
      handleClose()
    }
  }

  // Move to the previous step
  const prevStep = () => {
    if (currentStep > 0) {
      setAnimate(false)
      setTimeout(() => {
        setCurrentStep(currentStep - 1)
        setAnimate(true)
      }, 300)
    }
  }

  // Set up tutorial steps
  const steps = isArabic
    ? [
        {
          title: 'مرحبًا بك!',
          content:
            'خمن كلمة اليوم في 6 محاولات. بعد كل تخمينة، ستتغير ألوان المربعات لتظهر مدى قربك من الإجابة الصحيحة.',
          example: null,
          icon: <LightBulbIcon className="w-8 h-8 text-yellow-500" />,
        },
        {
          title: 'الحرف صحيح!',
          content: 'الحرف "ر" موجود في الكلمة وفي المكان الصحيح.',
          example: ['س', 'ر', 'ي', 'ع', 'ة'],
          statuses: [null, 'correct', null, null, null],
          icon: <CheckIcon className="w-8 h-8 text-green-500" />,
        },
        {
          title: 'الحرف موجود!',
          content: 'الحرف "ا" موجود في الكلمة ولكن في المكان الخطأ.',
          example: ['ك', 'ا', 'ت', 'ب', 'ة'],
          statuses: [null, 'present', null, null, null],
          icon: <CheckIcon className="w-8 h-8 text-yellow-500" />,
        },
        {
          title: 'الحرف غير موجود!',
          content: 'الحرف "ص" غير موجود في الكلمة على الإطلاق.',
          example: ['ق', 'ص', 'ي', 'د', 'ة'],
          statuses: [null, 'absent', null, null, null],
          icon: <XCircleIcon className="w-8 h-8 text-gray-500" />,
        },
        {
          title: 'لنلعب!',
          content:
            'اكتب كلمة مكونة من 5 أحرف ثم اضغط على "Enter" للتخمين. لديك 6 محاولات لمعرفة الكلمة!',
          example: null,
          icon: <LightBulbIcon className="w-8 h-8 text-purple-500" />,
        },
      ]
    : [
        {
          title: 'Welcome!',
          content:
            'Guess the word in 6 tries. After each guess, the colors will show how close you were.',
          example: null,
          icon: <LightBulbIcon className="w-8 h-8 text-yellow-500" />,
        },
        {
          title: 'Correct Letter!',
          content: 'The letter "E" is in the word and in the correct spot.',
          example: ['W', 'E', 'A', 'R', 'Y'],
          statuses: [null, 'correct', null, null, null],
          icon: <CheckIcon className="w-8 h-8 text-green-500" />,
        },
        {
          title: 'Letter Present!',
          content: 'The letter "I" is in the word but in the wrong spot.',
          example: ['P', 'I', 'L', 'O', 'T'],
          statuses: [null, 'present', null, null, null],
          icon: <CheckIcon className="w-8 h-8 text-yellow-500" />,
        },
        {
          title: 'Letter Absent!',
          content: 'The letter "U" is not in the word at all.',
          example: ['V', 'A', 'G', 'U', 'E'],
          statuses: [null, null, null, 'absent', null],
          icon: <XCircleIcon className="w-8 h-8 text-gray-500" />,
        },
        {
          title: "Let's Play!",
          content:
            'Type a 5-letter word and press "Enter" to guess. You have 6 tries to get the word!',
          example: null,
          icon: <LightBulbIcon className="w-8 h-8 text-purple-500" />,
        },
      ]

  const currentStepData = steps[currentStep]

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
              dir={isArabic ? 'rtl' : 'ltr'}
            >
              {/* Progress indicators */}
              <div className="flex justify-center mb-4">
                {steps.map((_, index) => (
                  <div
                    key={index}
                    className={`w-2 h-2 rounded-full mx-1 transition-all duration-300 ${
                      index === currentStep
                        ? 'bg-blue-600 w-6'
                        : index < currentStep
                        ? 'bg-blue-400'
                        : 'bg-gray-300'
                    }`}
                  />
                ))}
              </div>

              {/* Step content */}
              <div className="relative">
                <Transition
                  show={animate}
                  enter="transition ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-4"
                >
                  <div>
                    <div className="flex items-center mb-3">
                      <div className="bg-gray-100 p-2 rounded-full">
                        {currentStepData.icon}
                      </div>
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-bold text-gray-900 ml-3"
                      >
                        {currentStepData.title}
                      </Dialog.Title>
                    </div>

                    <div className="my-4">
                      <p className="text-gray-700">{currentStepData.content}</p>
                    </div>

                    {currentStepData.example && (
                      <div
                        className="my-6 flex justify-center"
                        dir={isArabic ? 'rtl' : 'ltr'}
                      >
                        {currentStepData.example.map((letter, i) => (
                          <Cell
                            key={i}
                            value={letter}
                            status={currentStepData.statuses?.[i] as CharStatus}
                            isArabic={isArabic}
                            small
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </Transition>
              </div>

              {/* Navigation buttons */}
              <div
                className={`mt-6 flex ${
                  isArabic ? 'flex-row-reverse' : 'flex-row'
                } justify-between`}
              >
                <button
                  type="button"
                  className={`px-3 py-2 text-sm font-medium rounded-md ${
                    currentStep > 0
                      ? 'text-gray-700 hover:text-gray-900'
                      : 'text-gray-400 cursor-not-allowed'
                  }`}
                  onClick={prevStep}
                  disabled={currentStep === 0}
                >
                  <div className="flex items-center">
                    <ArrowRightIcon className="w-4 h-4 ml-1" />
                    السابق
                  </div>
                </button>

                <button
                  type="button"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={nextStep}
                >
                  <div className="flex items-center">
                    <ArrowLeftIcon className="w-4 h-4 ml-1" />
                    {currentStep < steps.length - 1 ? 'التالي' : 'ابدأ اللعب'}
                  </div>
                </button>
              </div>

              {/* Skip button */}
              <button
                type="button"
                onClick={handleClose}
                className="absolute top-0 right-0 mt-2 mr-2 text-gray-400 hover:text-gray-500 focus:outline-none"
              >
                <span className="sr-only">Close</span>
                <XCircleIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default OnboardingTutorial
