import React, { useState, useEffect } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XCircleIcon,
} from '@heroicons/react/outline'
import { Cell } from '../grid/Cell'
import { CharStatus } from '../../helpers/statuses'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

interface ModalContainerProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  title?: string
  showCloseButton?: boolean
  size?: 'sm' | 'md' | 'lg'
  isArabic?: boolean
}

const ModalContainer: React.FC<ModalContainerProps> = ({
  isOpen,
  onClose,
  children,
  title,
  showCloseButton = true,
  size = 'sm',
  isArabic = false,
}) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true)
      setIsAnimating(true)
      const timer = setTimeout(() => setIsAnimating(false), 300)
      return () => clearTimeout(timer)
    } else {
      setIsAnimating(true)
      const timer = setTimeout(() => setIsVisible(false), 300)
      return () => clearTimeout(timer)
    }
  }, [isOpen])

  if (!isVisible) return null

  const sizeClasses = {
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
  }

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      {/* Backdrop */}
      <div
        className={`
          fixed inset-0 bg-black 
          transition-opacity duration-300 ease-in-out
          ${isOpen && !isAnimating ? 'opacity-50' : 'opacity-0'}
        `}
        onClick={onClose}
      />

      {/* Modal Positioning */}
      <div className="flex min-h-screen items-center justify-center px-4 py-6">
        {/* Modal Container */}
        <div
          className={`
            relative w-full ${sizeClasses[size]}
            transform overflow-hidden rounded-xl bg-white shadow-2xl
            transition-all duration-300 ease-in-out
            ${
              isOpen && !isAnimating
                ? 'opacity-100 translate-y-0 scale-100'
                : 'opacity-0 translate-y-4 scale-95'
            }
          `}
          dir={isArabic ? 'rtl' : 'ltr'}
        >
          {/* Modal Header */}
          {(title || showCloseButton) && (
            <div className="relative px-6 pt-5 pb-4 border-b border-gray-200">
              {title && (
                <h3
                  className={`text-xl font-semibold text-gray-900 ${
                    isArabic ? 'text-right' : 'text-left'
                  }`}
                >
                  {title}
                </h3>
              )}

              {showCloseButton && (
                <button
                  onClick={onClose}
                  className={`
                    absolute top-4 
                    ${isArabic ? 'left-4' : 'right-4'}
                    text-gray-400 hover:text-gray-500
                    transition-colors duration-200
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                  `}
                >
                  <XCircleIcon className="h-6 w-6" />
                </button>
              )}
            </div>
          )}

          {/* Modal Content */}
          <div
            className={`
              px-2 py-4
              ${
                isOpen && !isAnimating
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 translate-y-4'
              }
              transition-all duration-300 delay-150
            `}
          >
            {children}
          </div>

          {/* Decorative Bottom Border */}
          <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500" />
        </div>
      </div>
    </div>
  )
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  const [activeExample, setActiveExample] = useState(0)
  const [isSliding, setIsSliding] = useState(false)
  const isArabic = process.env.REACT_APP_LANG === 'Arabic'

  const examples = isArabic
    ? [
        {
          word: ['ت', 'ر', 'ح', 'ي', 'ب'],
          statuses: [null, null, null, null, 'correct'],
          explanation: 'الحرف ب موجود في الكلمة و في المكان المناسب',
        },
        {
          word: ['ع', 'ر', 'ب', 'ي', 'ة'],
          statuses: [null, 'present', null, null, null],
          explanation: 'الحرف ر موجود في الكلمة ولكن في المكان الخطأ',
        },
        {
          word: ['ك', 'ل', 'م', 'ا', 'ت'],
          statuses: [null, 'absent', null, null, null],
          explanation: 'الحرف ل غير موجود في الكلمة',
        },
      ]
    : [
        {
          word: ['W', 'E', 'A', 'R', 'Y'],
          statuses: ['correct', null, null, null, null],
          explanation: 'The letter W is in the word and in the correct spot.',
        },
        {
          word: ['P', 'I', 'L', 'O', 'T'],
          statuses: [null, null, 'present', null, null],
          explanation: 'The letter L is in the word but in the wrong spot.',
        },
        {
          word: ['V', 'A', 'G', 'U', 'E'],
          statuses: [null, null, null, 'absent', null],
          explanation: 'The letter U is not in the word in any spot.',
        },
      ]

  const handleNext = () => {
    if (isSliding) return
    setIsSliding(true)
    setTimeout(() => {
      setActiveExample((prev) => (prev + 1) % examples.length)
      setIsSliding(false)
    }, 300)
  }

  const handlePrev = () => {
    if (isSliding) return
    setIsSliding(true)
    setTimeout(() => {
      setActiveExample((prev) => (prev - 1 + examples.length) % examples.length)
      setIsSliding(false)
    }, 300)
  }

  useEffect(() => {
    if (!isOpen) return

    const advanceSlide = () => {
      setIsSliding(true)
      setTimeout(() => {
        setActiveExample((prev) => (prev + 1) % examples.length)
        setIsSliding(false)
      }, 300)
    }

    const timer = setInterval(advanceSlide, 3000)
    return () => {
      clearInterval(timer)
      setIsSliding(false)
    }
  }, [isOpen, isArabic])

  // Reset state when modal closes
  useEffect(() => {
    if (!isOpen) {
      setActiveExample(0)
      setIsSliding(false)
    }
  }, [isOpen])

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={handleClose}
      title={isArabic ? 'كيف تلعب؟' : 'How to play?'}
      isArabic={isArabic}
    >
      <div dir={isArabic ? 'rtl' : 'ltr'}>
        {/* Header text */}
        <p className="text-sm text-gray-600 text-center mb-4">
          {isArabic
            ? 'كل يوم، يتم اختيار كلمة مكونة من 5 أحرف عشوائيًا. عليك أن تخمنها في ' +
              Number(process.env.REACT_APP_LENGTH_OF_LINES) +
              ' محاولات'
            : 'Guess the word in ' +
              Number(process.env.REACT_APP_LENGTH_OF_LINES) +
              ' tries. After each guess, the colors will show how close you were.'}
        </p>
        {isArabic && (
          <p className="text-sm text-gray-500 text-center mb-8">
            خمن كلمة اليوم الصحيحة قبل انتهاء كل المحاولات علما أن كل تخمينة
            تقربك من الحل حيث يتغير لون المربعات حسب قربك من الكلمة الصحيحة
          </p>
        )}

        {/* Fixed-height container for examples */}
        <div className="relative h-44">
          {/* Navigation buttons */}
          <button
            onClick={isArabic ? handleNext : handlePrev}
            className="absolute left-0 top-1/2 -translate-y-1/2 z-10 w-8 h-8 flex items-center justify-center rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
            aria-label={isArabic ? 'Next example' : 'Previous example'}
          >
            <ChevronLeftIcon className="h-5 w-5 text-gray-600" />
          </button>
          <button
            onClick={isArabic ? handlePrev : handleNext}
            className="absolute right-0 top-1/2 -translate-y-1/2 z-10 w-8 h-8 flex items-center justify-center rounded-full bg-gray-100 hover:bg-gray-200 transition-colors"
            aria-label={isArabic ? 'Previous example' : 'Next example'}
          >
            <ChevronRightIcon className="h-5 w-5 text-gray-600" />
          </button>

          {/* Fixed-width example container */}
          <div className="mx-12">
            {/* Example content wrapper */}
            <div className="relative h-44">
              {examples.map((example, idx) => (
                <div
                  key={idx}
                  className={`
                    absolute inset-0 w-full
                    transition-all duration-300 ease-in-out
                    ${
                      activeExample === idx
                        ? 'opacity-100 translate-x-0 z-10'
                        : `opacity-0 ${
                            (activeExample === 0 &&
                              idx === examples.length - 1) ||
                            (activeExample > idx &&
                              !(
                                activeExample === examples.length - 1 &&
                                idx === 0
                              ))
                              ? '-translate-x-full'
                              : 'translate-x-full'
                          }`
                    }
                  `}
                >
                  <div className="bg-gray-50 rounded-xl p-6 h-full flex flex-col items-center justify-center">
                    {/* Letters display */}
                    <div className="flex justify-center mb-6">
                      {example.word.map((letter, i) => (
                        <Cell
                          key={i}
                          value={letter}
                          status={example.statuses[i] as CharStatus}
                          isArabic={isArabic}
                          small
                        />
                      ))}
                    </div>

                    {/* Explanation text */}
                    <p className="text-sm text-gray-600 text-center">
                      {example.explanation}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Progress dots */}
        <div className="flex justify-center items-center gap-2 mt-6">
          {examples.map((_, idx) => (
            <button
              key={idx}
              onClick={() => {
                if (isSliding) return
                setIsSliding(true)
                setTimeout(() => {
                  setActiveExample(idx)
                  setIsSliding(false)
                }, 300)
              }}
              className={`
                transition-all duration-300 ease-in-out
                rounded-full 
                ${
                  activeExample === idx
                    ? 'w-8 h-2 bg-blue-600'
                    : 'w-2 h-2 bg-gray-300 hover:bg-gray-400'
                }
              `}
            />
          ))}
        </div>
      </div>
    </ModalContainer>
  )
}

export default InfoModal
