// Key.tsx - Improved responsive Key Component
import React, { useEffect, useState } from 'react'
import { KeyValue } from '../../helpers/keyboard'
import { CharStatus } from '../../helpers/statuses'

interface KeyProps {
  value: KeyValue
  status?: CharStatus
  width?: number
  onClick: (value: KeyValue) => void
  children?: React.ReactNode
  bgColor?: string
  isPressed?: boolean
}

export const Key: React.FC<KeyProps> = ({
  value,
  status,
  onClick,
  children,
  bgColor,
  isPressed = false,
}) => {
  // Track viewport size to adjust key sizes
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth)
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth)
      setViewportHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Enhanced colors with gradients for better visual feedback
  const getColors = () => {
    if (!status) {
      return 'bg-gradient-to-b from-gray-100 to-gray-200 text-gray-900 hover:from-gray-200 hover:to-gray-300 active:from-gray-300 active:to-gray-400 shadow-sm border border-gray-300'
    }
    switch (status) {
      case 'correct':
        return 'bg-gradient-to-b from-green-500 to-green-600 text-white hover:from-green-600 hover:to-green-700 active:from-green-700 active:to-green-800 shadow-md border border-green-400'
      case 'present':
        return 'bg-gradient-to-b from-yellow-500 to-amber-600 text-white hover:from-yellow-600 hover:to-amber-700 active:from-yellow-700 active:to-amber-800 shadow-md border border-yellow-400'
      case 'absent':
        return 'bg-gradient-to-b from-gray-500 to-gray-600 text-white hover:from-gray-600 hover:to-gray-700 active:from-gray-700 active:to-gray-800 shadow-sm border border-gray-400'
      default:
        return 'bg-gradient-to-b from-gray-100 to-gray-200 text-gray-900 hover:from-gray-200 hover:to-gray-300 active:from-gray-300 active:to-gray-400 shadow-sm border border-gray-300'
    }
  }

  // Responsive key sizing - consistent across all device sizes but scaled appropriately
  const getKeyWidth = () => {
    // The same layout structure, just different sizes based on screen
    if (value === 'ENTER' || value === 'DELETE') {
      // We want ENTER and DELETE to always be wider than regular keys
      return 'w-[clamp(40px,10vw,65px)]' // Ensures minimum 40px, maximum 65px
    }
    // Regular letter keys
    return 'w-[clamp(24px,6vw,36px)]' // Ensures minimum 24px, maximum 36px
  }

  // Responsive height for keys
  const getKeyHeight = () => {
    return 'h-[clamp(32px,7vh,45px)]' // Ensures minimum 32px, maximum 45px
  }

  // Adaptive text size - smaller font on smaller keys
  const getTextSize = () => {
    if (viewportWidth < 350) {
      return 'text-[10px]' // Even smaller text for very small screens
    } else if (viewportWidth < 400) {
      return 'text-[11px]' // Smaller text for small screens
    } else {
      return 'text-[12px] sm:text-[14px]' // Larger text for bigger screens
    }
  }

  // Adaptive margin - smaller margin on smaller screens to fit all keys
  const getKeyMargin = () => {
    if (viewportWidth < 350) {
      return 'm-[0.05rem]' // Tiny margins for very small screens
    } else if (viewportWidth < 400) {
      return 'm-[0.1rem]' // Smaller margins for small screens
    } else {
      return 'm-[0.15rem] sm:m-[0.2rem]' // Larger margins for bigger screens
    }
  }

  return (
    <button
      onClick={() => onClick(value)}
      className={`
        ${getKeyWidth()}
        ${getKeyHeight()}
        ${getKeyMargin()}
        flex items-center justify-center
        rounded-lg
        font-bold
        ${getTextSize()}
        transition-all duration-150
        cursor-pointer
        transform active:scale-95 active:translate-y-0.5
        select-none
        ${getColors()}
        ${isPressed ? 'key-pressed scale-90' : 'scale-100'}
        relative overflow-hidden
      `}
      style={
        bgColor
          ? {
              WebkitTapHighlightColor: 'transparent',
              background: bgColor,
              backgroundImage: `linear-gradient(to bottom, ${bgColor}E6, ${bgColor})`,
            }
          : {
              WebkitTapHighlightColor: 'transparent',
            }
      }
    >
      {/* Ripple effect for key press */}
      <span className="absolute inset-0 key-ripple"></span>

      {/* Inner highlight effect for better dimension */}
      <span className="absolute inset-0 bg-gradient-to-b from-white/20 to-transparent opacity-50 rounded-t-lg h-1/2"></span>

      {/* Key content with text shadow for better readability */}
      <span
        className={`
        relative z-10 
        ${status === 'correct' || status === 'present' ? 'text-shadow-sm' : ''}
      `}
      >
        {children || value}
      </span>
    </button>
  )
}

export default Key
