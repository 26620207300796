import { CharStatus } from '../../helpers/statuses'
import classnames from 'classnames'

type Props = {
  status: CharStatus
  small?: boolean
}

export const MiniCell = ({ status, small }: Props) => {
  const classes = classnames(
    `${small ? 'w-7 h-7' : 'w-10 h-10'}`,
    'border-solid border-2 border-slate-200 flex items-center justify-center mx-0.5 text-lg font-bold rounded',
    {
      'bg-slate-400': status === 'absent',
      'bg-green-500': status === 'correct',
      'bg-yellow-500': status === 'present',
    }
  )

  return (
    <>
      <div className={classes}></div>
    </>
  )
}
