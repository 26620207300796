// Cell.tsx - Responsive Cell Component
import React, { useState, useEffect } from 'react'
import { CharStatus } from '../../helpers/statuses'

interface CellProps {
  value?: string
  status?: CharStatus
  isRevealing?: boolean
  revealDelay?: number
  isArabic?: boolean
  small?: boolean
  cellSize?: {
    width: string
    height: string
    fontSize: string
    gap: string
  }
}

export const Cell: React.FC<CellProps> = ({
  value,
  status,
  isRevealing = false,
  revealDelay = 0,
  isArabic = false,
  small = false,
  cellSize,
}) => {
  const [showStatus, setShowStatus] = useState(false)
  const [isFlipping, setIsFlipping] = useState(false)
  const [hasRevealed, setHasRevealed] = useState(false)

  useEffect(() => {
    if (isRevealing) {
      setIsFlipping(true)
      setShowStatus(false)

      // Wait for the flip to reach halfway point before showing status
      const statusTimer = setTimeout(() => {
        setShowStatus(true)
      }, revealDelay * 300 + 400)

      const flipTimer = setTimeout(() => {
        setIsFlipping(false)
        setHasRevealed(true)
      }, revealDelay * 300 + 800)

      return () => {
        clearTimeout(statusTimer)
        clearTimeout(flipTimer)
      }
    } else {
      setShowStatus(!!status)
    }
  }, [isRevealing, revealDelay, status])

  // Enhanced cell backgrounds with gradients for better visual depth
  const getCellBackground = () => {
    if (!showStatus || !status)
      return 'bg-gradient-to-br from-gray-700 to-gray-800 border-gray-600'
    switch (status) {
      case 'correct':
        return 'bg-gradient-to-br from-green-500 to-green-600 border-green-400'
      case 'present':
        return 'bg-gradient-to-br from-yellow-500 to-amber-600 border-yellow-400'
      case 'absent':
        return 'bg-gradient-to-br from-gray-500 to-gray-600 border-gray-400'
      default:
        return 'bg-gradient-to-br from-gray-700 to-gray-800 border-gray-600'
    }
  }

  // Enhanced animation classes for more engaging visuals
  const getAnimationClasses = () => {
    // Base animation for flipping
    const baseAnimation = isFlipping ? 'animate-flip-3d' : ''

    // Additional animations based on status
    if (hasRevealed) {
      switch (status) {
        case 'correct':
          return `${baseAnimation} animate-pulse-gentle`
        case 'present':
          return `${baseAnimation} animate-glow-subtle`
        default:
          return baseAnimation
      }
    }

    return baseAnimation
  }

  // Enhanced shadow effects based on status for better visual depth
  const getShadowEffect = () => {
    if (!showStatus || !status) return 'shadow-sm'

    switch (status) {
      case 'correct':
        return 'shadow-lg shadow-green-500/30'
      case 'present':
        return 'shadow-lg shadow-yellow-500/30'
      case 'absent':
        return 'shadow-md shadow-gray-500/20'
      default:
        return 'shadow-sm'
    }
  }

  // Default cell size if not provided
  const defaultCellSize = {
    width: small ? '28px' : '36px',
    height: small ? '28px' : '36px',
    fontSize: small ? '12px' : '18px',
    gap: '0.25rem',
  }

  // Use provided cell size or default
  const { width, height, fontSize } = cellSize || defaultCellSize

  return (
    <div
      className={`
        cell-component
        aspect-square
        flex items-center justify-center
        border-2 rounded-lg
        mx-0.5
        transition-all duration-500
        ${getCellBackground()}
        ${value ? 'text-white' : ''}
        ${getAnimationClasses()}
        ${getShadowEffect()}
        relative overflow-hidden
      `}
      style={{
        width,
        height,
        fontSize: value ? fontSize : '0',
        animationDelay: isRevealing ? `${revealDelay * 300}ms` : undefined,
        direction: isArabic ? 'rtl' : 'ltr',
      }}
    >
      {/* Inner glow effect for correct/present status */}
      {showStatus && (status === 'correct' || status === 'present') && (
        <div
          className={`absolute inset-0 rounded-md opacity-30 ${
            status === 'correct' ? 'cell-glow-green' : 'cell-glow-yellow'
          }`}
        ></div>
      )}

      {/* Letter content with enhanced pop animation */}
      <div className={`relative z-10 ${value ? 'animate-pop-enhanced' : ''}`}>
        {value}
      </div>
    </div>
  )
}

export default Cell
