import React, { useEffect, useState } from 'react'
import { XCircleIcon, HeartIcon } from '@heroicons/react/outline' // Using HeroIcons instead of lucide-react

type Props = {
  isOpen: boolean
  handleClose: () => void
}

interface ModalContainerProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  title?: string
  showCloseButton?: boolean
  size?: 'sm' | 'md' | 'lg'
  isArabic?: boolean
}

const ModalContainer: React.FC<ModalContainerProps> = ({
  isOpen,
  onClose,
  children,
  title,
  showCloseButton = true,
  size = 'sm',
  isArabic = false,
}) => {
  const [isAnimating, setIsAnimating] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true)
      setIsAnimating(true)
      const timer = setTimeout(() => setIsAnimating(false), 300)
      return () => clearTimeout(timer)
    } else {
      setIsAnimating(true)
      const timer = setTimeout(() => setIsVisible(false), 300)
      return () => clearTimeout(timer)
    }
  }, [isOpen])

  if (!isVisible) return null

  const sizeClasses = {
    sm: 'max-w-sm',
    md: 'max-w-md',
    lg: 'max-w-lg',
  }

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      {/* Backdrop */}
      <div
        className={`
          fixed inset-0 bg-black 
          transition-opacity duration-300 ease-in-out
          ${isOpen && !isAnimating ? 'opacity-50' : 'opacity-0'}
        `}
        onClick={onClose}
      />

      {/* Modal Positioning */}
      <div className="flex min-h-screen items-center justify-center px-4 py-6">
        {/* Modal Container */}
        <div
          className={`
            relative w-full ${sizeClasses[size]}
            transform overflow-hidden rounded-xl bg-white shadow-2xl
            transition-all duration-300 ease-in-out
            ${
              isOpen && !isAnimating
                ? 'opacity-100 translate-y-0 scale-100'
                : 'opacity-0 translate-y-4 scale-95'
            }
          `}
          dir={isArabic ? 'rtl' : 'ltr'}
        >
          {/* Modal Header */}
          {(title || showCloseButton) && (
            <div className="relative px-6 pt-5 pb-4 border-b border-gray-200">
              {title && (
                <h3
                  className={`text-xl font-semibold text-gray-900 ${
                    isArabic ? 'text-right' : 'text-left'
                  }`}
                >
                  {title}
                </h3>
              )}

              {showCloseButton && (
                <button
                  onClick={onClose}
                  className={`
                    absolute top-4 
                    ${isArabic ? 'left-4' : 'right-4'}
                    text-gray-400 hover:text-gray-500
                    transition-colors duration-200
                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                  `}
                >
                  <XCircleIcon className="h-6 w-6" />
                </button>
              )}
            </div>
          )}

          {/* Modal Content */}
          <div
            className={`
              px-2 py-4
              ${
                isOpen && !isAnimating
                  ? 'opacity-100 translate-y-0'
                  : 'opacity-0 translate-y-4'
              }
              transition-all duration-300 delay-150
            `}
          >
            {children}
          </div>

          {/* Decorative Bottom Border */}
          <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500" />
        </div>
      </div>
    </div>
  )
}

const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-3 w-3 sm:h-4 sm:w-4"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </svg>
)

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  const isArabic = process.env.REACT_APP_LANG === 'Arabic'

  const SocialLink = ({
    href,
    icon: Icon,
    label,
  }: {
    href: string
    icon: React.ReactNode
    label: string
  }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors duration-200"
      aria-label={label}
    >
      {Icon}
      <span className="text-sm">{label}</span>
    </a>
  )

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={handleClose}
      title={isArabic ? 'عن التطبيق' : 'About'}
      isArabic={isArabic}
    >
      <div className="space-y-6" dir={isArabic ? 'rtl' : 'ltr'}>
        {/* Game Info */}
        <div className="text-center">
          <h2 className="text-xl font-bold text-gray-900 mb-2">
            {isArabic ? 'يومية' : 'Yawmiya'}
          </h2>
          <p className="text-sm text-gray-600">
            {isArabic
              ? 'لعبة كلمات يومية تتحدى مهاراتك في تخمين الكلمة الصحيحة'
              : 'A daily word game that challenges your skills in guessing the right word'}
          </p>
        </div>

        {/* Creator Info */}
        <div className="bg-gradient-to-r from-blue-50 to-purple-50 rounded-lg p-4">
          <div className="flex items-center justify-center gap-2 mb-2">
            <p className="text-sm text-gray-700">
              {isArabic
                ? 'تم التطوير بفضل الله و مساندة العائلة و الأصدقاء'
                : 'Developed by'}
            </p>
            <HeartIcon className="h-4 w-4 text-red-500 animate-pulse" />
          </div>
          <div className="flex justify-center space-x-4">
            <SocialLink
              href="https://twitter.com/YawmiyaGame"
              icon={<TwitterIcon />}
              label="@YawmiyaGame"
            />
            {/* Add more social links as needed */}
          </div>
        </div>

        {/* Version Info */}
        <div className="text-center text-xs text-gray-400">
          {isArabic ? 'الإصدار' : 'Version'} 1.0.0
        </div>
      </div>
    </ModalContainer>
  )
}

export default AboutModal
