// TopNav.tsx - Centered header component for desktop
import React from 'react'
import {
  QuestionMarkCircleIcon,
  ShareIcon,
  BellIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'

interface TopNavProps {
  isPushNotifsEnabled: boolean | undefined
  onShare: () => void
  onReminder: () => void
  onHowToPlay: () => void
  onAbout: () => void
  isArabic: boolean
}

export const TopNav: React.FC<TopNavProps> = ({
  isPushNotifsEnabled,
  onShare,
  onReminder,
  onHowToPlay,
  onAbout,
  isArabic,
}) => {
  return (
    <header className="game-header fixed top-0 left-0 right-0 bg-black shadow-lg z-50 h-10 sm:h-12 flex justify-center">
      {/* Max-width container to match game width on desktop */}
      <div className="w-full max-w-md sm:max-w-lg md:max-w-xl px-2 sm:px-4 flex items-center justify-between">
        {/* Left side buttons - order reversed for Arabic */}
        <div
          className={`flex items-center gap-1 ${
            isArabic ? 'order-last' : 'order-first'
          }`}
        >
          <button
            onClick={onHowToPlay}
            className="p-1 hover:bg-gray-800 rounded-full transition-colors"
            aria-label={isArabic ? 'كيف تلعب' : 'How to play'}
          >
            <QuestionMarkCircleIcon className="w-4 h-4 sm:w-5 sm:h-5 text-white" />
          </button>
          <button
            onClick={onAbout}
            className="p-1 hover:bg-gray-800 rounded-full transition-colors"
            aria-label={isArabic ? 'من نحن' : 'About us'}
          >
            <UserGroupIcon className="w-4 h-4 sm:w-5 sm:h-5 text-white" />
          </button>
        </div>

        {/* Center title */}
        <div className="flex-1 text-center">
          <h3
            className="text-sm sm:text-base text-white whitespace-nowrap"
            dir={isArabic ? 'rtl' : 'ltr'}
          >
            yawmiya.co
          </h3>
        </div>

        {/* Right side buttons - order reversed for Arabic */}
        <div
          className={`flex items-center gap-1 ${
            isArabic ? 'order-first' : 'order-last'
          }`}
        >
          {typeof navigator !== 'undefined' && 'share' in navigator && (
            <button
              onClick={onShare}
              className="p-1 hover:bg-gray-800 rounded-full transition-colors"
              aria-label={isArabic ? 'شارك اللعبة' : 'Share game'}
            >
              <ShareIcon className="w-4 h-4 sm:w-5 sm:h-5 text-white" />
            </button>
          )}
          {isPushNotifsEnabled === false && (
            <button
              onClick={onReminder}
              className="p-1 hover:bg-gray-800 rounded-full transition-colors"
              aria-label={isArabic ? 'تذكير' : 'Enable reminders'}
            >
              <BellIcon className="w-4 h-4 sm:w-5 sm:h-5 text-white" />
            </button>
          )}
        </div>
      </div>

      {/* Animated underline that spans full width */}
      <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-gradient-to-r from-green-400 via-yellow-400 to-green-400 animate-gradient" />
    </header>
  )
}

export default TopNav
