import { InformationCircleIcon } from '@heroicons/react/outline'
import OnboardingTutorial from './components/onboarding'
import { useState, useEffect, useCallback, useRef } from 'react'
import OneSignal from 'react-onesignal'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { WinModal } from './components/modals/WinModal'
import { RefreshModal } from './components/modals/RefreshModal'
import { isWinningWord, solutionIndex } from './helpers/words'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  clearGameStateToLocalStorage,
  saveLastDateToLocalStorage,
  loadLastDateFromLocalStorage,
  clearLastDateToLocalStorage,
  saveNextDayDateToLocalStorage,
  clearNextDayDateToLocalStorage,
  loadNextDayDateFromLocalStorage,
  saveStartDateToLocalStorage,
  loadStartDateDateFromLocalStorage,
  clearStartDateToLocalStorage,
  GameStats,
  loadStats,
  addStatsForCompletedGame,
  saveStats,
} from './helpers/localStorage'
import { TopNav } from './components/top-nav'
import { detectMob } from './helpers/device'
import { initHotjar } from './helpers/hotjar'

const msToTime = (duration: number) => {
  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  const hoursFormatted = hours < 10 ? '0' + hours : hours
  const minutesFormatted = minutes < 10 ? '0' + minutes : minutes
  const secondsFormatted = seconds < 10 ? '0' + seconds : seconds

  return hoursFormatted + ':' + minutesFormatted + ':' + secondsFormatted
}

const restFrom24hours = (duration: number) => {
  return msToTime(duration)
}

const daysBetween = (first: Date, second: Date) => {
  var one = new Date(first.getFullYear(), first.getMonth(), first.getDate())
  var two = new Date(second.getFullYear(), second.getMonth(), second.getDate())

  var millisecondsPerDay = 1000 * 60 * 60 * 24
  var millisBetween = two.getTime() - one.getTime()
  var days = millisBetween / millisecondsPerDay

  return Math.floor(days)
}

const isMoreThanADay = () => {
  const now = Date.now()
  const nextDayDate = loadNextDayDateFromLocalStorage()
  return nextDayDate && now - nextDayDate > 0
}

const isMoreThanADayFromStart = () => {
  const now = new Date()
  const startDateFromLocalStorage = loadStartDateDateFromLocalStorage()
  if (!!startDateFromLocalStorage) {
    const startDate = new Date(startDateFromLocalStorage)
    return startDate && daysBetween(startDate, now) > 0
  }
  return false
}

// Modified function to calculate next day in UTC
const calculateNextUTCDay = () => {
  const nextDay = new Date()
  // Set to next UTC day at midnight
  nextDay.setUTCHours(0, 0, 0, 0)
  nextDay.setUTCDate(nextDay.getUTCDate() + 1)
  return nextDay.getTime()
}

// Toast Notification Component
const ToastNotification = ({
  message,
  isVisible,
  icon,
}: {
  message: string
  isVisible: boolean
  icon?: React.ReactNode
}) => {
  // Using a ref to track the animation state
  const toastRef = useRef<HTMLDivElement>(null)
  const [isAnimating, setIsAnimating] = useState(false)

  // Control the animation and visibility properly
  useEffect(() => {
    if (isVisible) {
      setIsAnimating(true)
      // Make sure we don't remove the element while it's visible
      if (toastRef.current) {
        toastRef.current.classList.remove('toast-exit')
        toastRef.current.classList.add('toast-enter')
      }
    } else if (isAnimating) {
      // Only start exit animation if we were previously visible
      if (toastRef.current) {
        toastRef.current.classList.remove('toast-enter')
        toastRef.current.classList.add('toast-exit')

        // Allow time for exit animation before stopping rendering
        const timer = setTimeout(() => {
          setIsAnimating(false)
        }, 300) // Match this to your CSS exit animation duration

        return () => clearTimeout(timer)
      }
    }
  }, [isVisible, isAnimating])

  // Don't render anything if not visible and not animating
  if (!isVisible && !isAnimating) return null

  return (
    <div
      ref={toastRef}
      className={`toast-notification ${
        isVisible ? 'toast-enter' : 'toast-exit'
      }`}
    >
      {icon}
      <span>{message}</span>
    </div>
  )
}

function App() {
  // Device and viewport state
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight)
  const [safeAreaBottom, setSafeAreaBottom] = useState(0)
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const appContainerRef = useRef<HTMLDivElement>(null)
  const shareTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  // App state
  const [oneSignalInitialized, setOneSignalInitialized] = useState(false)
  const [solution, setSolution] = useState('')
  const [isPushNotifsEnabled, setIsPushNotifsEnabled] = useState<
    boolean | undefined
  >(undefined)
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isWinModalOpen, setIsWinModalOpen] = useState(false)
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [shareComplete, setShareComplete] = useState(false)
  const [nowTime, setNowTime] = useState(Date.now())
  const [guesses, setGuesses] = useState<string[]>([])
  const [pageLoaded, setPageLoaded] = useState(false)

  // Keyboard state
  const [useNativeKeyboard, setUseNativeKeyboard] = useState(false)
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null)
  const [isRevealing, setIsRevealing] = useState<boolean>(false)
  const [stats, setStats] = useState<GameStats>(loadStats())

  // App-specific data
  const lastDate = loadLastDateFromLocalStorage()
  const nextDayDate = loadNextDayDateFromLocalStorage()
  const isArabic = process.env.REACT_APP_LANG === 'Arabic'

  useEffect(() => {
    initHotjar(5330484, 6)
  }, [])

  // Handle mobile viewport and safe area adjustments
  useEffect(() => {
    // Fix for mobile viewport height issues
    const updateViewportHeight = () => {
      // Get the actual viewport height
      setViewportHeight(window.innerHeight)

      // Calculate safe area at bottom for iOS devices
      const safeAreaBottom =
        'env(safe-area-inset-bottom)' in document.documentElement.style
          ? parseInt(
              getComputedStyle(document.documentElement).getPropertyValue(
                'env(safe-area-inset-bottom)'
              )
            )
          : 0

      setSafeAreaBottom(safeAreaBottom)

      // Apply the height to the app container
      if (appContainerRef.current) {
        appContainerRef.current.style.height = `${window.innerHeight}px`
      }
    }

    // Initial setup
    updateViewportHeight()

    // Apply workaround for iOS Safari 100vh issue
    if (detectMob()) {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      )
    }

    // Update on resize
    window.addEventListener('resize', updateViewportHeight)

    // Update on orientation change
    window.addEventListener('orientationchange', () => {
      // Small delay to ensure the browser has updated its dimensions
      setTimeout(updateViewportHeight, 100)
    })

    return () => {
      window.removeEventListener('resize', updateViewportHeight)
      window.removeEventListener('orientationchange', updateViewportHeight)
    }
  }, [])

  // Setup improved keyboard visibility detection
  useEffect(() => {
    // Function to check if elements are visible in the viewport
    const checkKeyboardVisibility = () => {
      const keyboardElement = document.querySelector('.keyboard-container')
      const enterButton = document.querySelector('.btnEnter')

      if (keyboardElement && enterButton) {
        const keyboardRect = keyboardElement.getBoundingClientRect()
        const enterRect = enterButton.getBoundingClientRect()

        const isKeyboardVisible = keyboardRect.top < window.innerHeight
        const isEnterVisible = enterRect.bottom < window.innerHeight

        // Add a visible class to the body to help with CSS targeting
        if (!isKeyboardVisible || !isEnterVisible) {
          document.body.classList.add('keyboard-partially-visible')

          // Scroll to make enter button visible if needed
          if (!isEnterVisible && isKeyboardVisible) {
            enterButton.scrollIntoView({ behavior: 'smooth', block: 'end' })
          }
        } else {
          document.body.classList.remove('keyboard-partially-visible')
        }
      }
    }

    // Check visibility initially and on window resize
    checkKeyboardVisibility()
    window.addEventListener('resize', checkKeyboardVisibility)

    return () => {
      window.removeEventListener('resize', checkKeyboardVisibility)
    }
  }, [])

  useEffect(() => {
    setStats(loadStats())
  }, [])

  useEffect(() => {
    const checkMobile = () => {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
      setIsMobileDevice(isMobile)
    }

    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  useEffect(() => {
    const checkMobile = () => {
      const isMobile = window.innerWidth < 768
      setIsMobileDevice(isMobile)

      // If on mobile, simplify some background effects for better performance
      if (isMobile) {
        // Remove or simplify some of the decorative background elements
        const bgElements = document.querySelectorAll(
          '.star, .animate-float-slow, .animate-float-slow-reverse'
        )
        bgElements.forEach((el) => {
          ;(el as HTMLElement).style.display = 'none'
        })

        // Simplify animations on mobile for better performance
        document.documentElement.classList.add('mobile-optimized')
      } else {
        document.documentElement.classList.remove('mobile-optimized')
      }
    }

    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID || '',
    }).then(() => {
      setOneSignalInitialized(true)
    })
    OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
      if (isEnabled) {
        setIsPushNotifsEnabled(true)
      } else {
        setIsPushNotifsEnabled(false)
      }
    })
  }, [oneSignalInitialized])

  const fetchWordData = async () => {
    try {
      // Set loading state first
      setPageLoaded(false)

      // const localhost = true ? 'localhost' : '10.0.2.2'
      const res = await fetch(`https://yawmiya.co/api?yaw=${solutionIndex}`)
      const result = await res.json()

      if (result?.today) {
        const loaded = loadGameStateFromLocalStorage()
        // Process the data
        if (loaded?.solution !== result.today) {
          setGuesses([])
        }
        if (loaded?.guesses?.includes(result.today)) {
          setIsGameWon(true)
        } else if (
          loaded?.guesses?.length ===
          Number(process.env.REACT_APP_LENGTH_OF_LINES)
        ) {
          setIsGameLost(true)
        } else if (!loaded?.guesses?.length || loaded?.guesses?.length === 0) {
          saveStartDateToLocalStorage(new Date())
        }
        setSolution(result.today)
        if (
          !!loaded?.guesses?.length &&
          loaded?.guesses?.length <
            Number(process.env.REACT_APP_LENGTH_OF_LINES) &&
          isMoreThanADayFromStart()
        ) {
          clearGameStateToLocalStorage()
          saveGameStateToLocalStorage({ guesses: [], solution: '' })
          clearLastDateToLocalStorage()
          clearNextDayDateToLocalStorage()
          saveStartDateToLocalStorage(new Date())
        } else {
          setGuesses(loaded?.guesses || [])
        }
      }

      // Use requestAnimationFrame for smoother transition
      requestAnimationFrame(() => {
        setPageLoaded(true)
      })
    } catch (error) {
      console.error('Error fetching data:', error)
      requestAnimationFrame(() => {
        setPageLoaded(true)
      })
    }
  }

  useEffect(() => {
    fetchWordData()
  }, [])

  useEffect(() => {
    let interval: any = null
    if (lastDate && nextDayDate) {
      if (oneSignalInitialized) {
        setTimeout(() => {
          OneSignal.showSlidedownPrompt().then(() => {
            // do other stuff
          })
        }, 5000)
      }
      interval = setInterval(() => {
        if (isMoreThanADay()) {
          clearInterval(interval)

          clearGameStateToLocalStorage()
          saveGameStateToLocalStorage({ guesses: [], solution: '' })
          clearLastDateToLocalStorage()
          clearNextDayDateToLocalStorage()

          window.location.reload()
        } else {
          setNowTime(Date.now())
        }
      }, 1000)
    } else {
      if (interval) {
        clearInterval(interval)
      }
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isGameLost, isGameWon])

  useEffect(() => {
    if (solution) {
      saveGameStateToLocalStorage({ guesses, solution: solution || '' })
    }
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      setIsWinModalOpen(true)
    }
  }, [isGameWon])

  // Handle physical keyboard input
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!useNativeKeyboard) {
        const key = event.key.toLowerCase()

        if (key === 'enter') {
          onEnter()
        } else if (key === 'backspace') {
          onDelete()
        } else if (key.length === 1 && key.match(/[a-zأ-ي]/i)) {
          onChar(key)
        }
      }
    },
    [currentGuess, guesses, useNativeKeyboard]
  )

  // Handle mobile keyboard input
  const handleMobileInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      if (value.length > 0) {
        const lastChar = value.charAt(value.length - 1)
        if (lastChar.match(/[a-zأ-ي]/i)) {
          onChar(lastChar)
        }
        // Clear the input after processing
        if (inputRef) {
          inputRef.value = ''
        }
      }
    },
    [currentGuess, guesses]
  )

  // Handle keyboard toggle
  const toggleKeyboard = useCallback(() => {
    setUseNativeKeyboard((prev) => !prev)
    if (!useNativeKeyboard) {
      // Switching to native keyboard
      setTimeout(() => {
        inputRef?.focus()
      }, 100)
    } else {
      // Switching to custom keyboard
      inputRef?.blur()
    }
  }, [useNativeKeyboard, inputRef])

  // Setup keyboard listeners
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  // Auto-focus management for native keyboard
  useEffect(() => {
    if (useNativeKeyboard && inputRef && !isGameWon && !isGameLost) {
      inputRef.focus()
    }
  }, [useNativeKeyboard, isGameWon, isGameLost])

  const onChar = (value: string) => {
    if (
      currentGuess.length < 5 &&
      guesses.length < Number(process.env.REACT_APP_LENGTH_OF_LINES)
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (
      currentGuess.length === 5 &&
      guesses.length < Number(process.env.REACT_APP_LENGTH_OF_LINES) &&
      !isGameWon
    ) {
      const winningWord = isWinningWord(currentGuess, solution)
      const newGuesses = [...guesses, currentGuess]
      setIsRevealing(true)
      setGuesses(newGuesses)
      setCurrentGuess('')

      // Important: Make sure animation has enough time to complete before state changes
      const animationDuration = 1750 // 1.75 seconds for animation

      // For 6th guess (final attempt), ensure animation completes
      const isFinalGuess =
        newGuesses.length === Number(process.env.REACT_APP_LENGTH_OF_LINES)
      const timeoutDuration = isFinalGuess
        ? animationDuration + 250
        : animationDuration

      setTimeout(() => {
        setIsRevealing(false)

        if (winningWord) {
          const newStats = addStatsForCompletedGame(
            stats,
            newGuesses.length - 1,
            true
          )
          setStats(newStats)
          saveStats(newStats)
          saveLastDateToLocalStorage(Date.now())

          const nextUTCDay = calculateNextUTCDay()
          saveNextDayDateToLocalStorage(nextUTCDay)

          setNowTime(Date.now())
          clearStartDateToLocalStorage()
          return setIsGameWon(true)
        }

        if (
          guesses.length ===
          Number(process.env.REACT_APP_LENGTH_OF_LINES) - 1
        ) {
          const newStats = addStatsForCompletedGame(
            stats,
            newGuesses.length - 1,
            false
          )
          setStats(newStats)
          saveStats(newStats)
          saveLastDateToLocalStorage(Date.now())

          const nextUTCDay = calculateNextUTCDay()
          saveNextDayDateToLocalStorage(nextUTCDay)

          setNowTime(Date.now())
          clearStartDateToLocalStorage()
          return setIsGameLost(true)
        }
      }, timeoutDuration)
    }
  }

  const shareTheGame = () => {
    if (navigator?.share) {
      const text = 'https://yawmiya.co'
      navigator
        ?.share({
          text,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error))
    }
  }

  const handleShareSuccess = () => {
    setShareComplete(true)

    // Clear any existing timeout to prevent issues
    if (shareTimeoutRef.current) {
      clearTimeout(shareTimeoutRef.current)
    }

    // Set a new timeout to hide the toast
    shareTimeoutRef.current = setTimeout(() => {
      setShareComplete(false)
    }, 2000)
  }

  const reminder = () => {
    if (oneSignalInitialized) {
      OneSignal.showSlidedownPrompt({ force: true }).then(() => {
        setIsPushNotifsEnabled(true)
      })
    }
  }

  const timeRested = nextDayDate ? restFrom24hours(nextDayDate - nowTime) : null

  if (!solution) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900">
        <div className="relative flex flex-col items-center">
          <div className="w-20 h-20 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
          <h2 className="mt-4 text-xl font-bold text-white">Loading word...</h2>
          <div className="mt-2 h-2 w-48 bg-gray-700 rounded-full overflow-hidden">
            <div className="h-full bg-gradient-to-r from-blue-500 to-green-500 animate-pulse"></div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      ref={appContainerRef}
      className="game-container h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white flex flex-col"
      style={{
        // Use calculated viewport height to avoid iOS Safari issues
        height: `${viewportHeight}px`,
        // Add padding at the bottom for iOS safe area
        paddingBottom: `${safeAreaBottom}px`,
        // Fade in animation when loaded
        opacity: pageLoaded ? 1 : 0,
        transition: 'opacity 0.5s ease-in-out',
      }}
    >
      {/* Background elements */}
      <div className="fixed inset-0 z-0 overflow-hidden pointer-events-none">
        <div className="absolute inset-0 bg-gradient-to-br from-indigo-900/10 via-purple-900/5 to-blue-900/10"></div>
      </div>

      {/* TopNav - fixed height */}
      <TopNav
        isPushNotifsEnabled={isPushNotifsEnabled}
        onShare={shareTheGame}
        onReminder={reminder}
        onHowToPlay={() => setIsInfoModalOpen(true)}
        onAbout={() => setIsAboutModalOpen(true)}
        isArabic={isArabic}
      />

      {/* Toast Notifications */}
      <ToastNotification
        message={isArabic ? 'تم نسخ النتيجة' : 'Game copied to clipboard'}
        isVisible={shareComplete}
        icon={
          <span role="img" aria-label="checkmark" className="animate-pop">
            ✅
          </span>
        }
      />

      {/* Main game content - calculated height */}
      <div className="game-content flex-1 flex flex-col overflow-hidden">
        {/* Hidden input for native keyboard */}
        <input
          ref={setInputRef}
          type="text"
          className="opacity-0 h-0 w-0 absolute top-0 left-0"
          onChange={handleMobileInput}
          autoComplete="off"
          autoCapitalize="none"
          autoCorrect="off"
          inputMode={isArabic ? 'text' : 'text'}
        />

        {/* Grid container - flexible height */}
        <div className="game-grid-container flex-1 min-h-0 flex items-center justify-center">
          <Grid
            guesses={guesses}
            currentGuess={currentGuess}
            isArabicKeyboard={isArabic}
            solution={solution}
            isRevealing={isRevealing}
          />
        </div>

        {/* Keyboard container - fixed height */}
        <div className="game-keyboard-container flex-shrink-0">
          {!useNativeKeyboard && (
            <Keyboard
              onChar={onChar}
              onDelete={onDelete}
              onEnter={onEnter}
              guesses={guesses}
              isArabicKeyboard={isArabic}
              solution={solution}
              isRevealing={isRevealing}
            />
          )}
        </div>
      </div>

      {/* Modals */}
      <WinModal
        isOpen={isWinModalOpen}
        solution={solution}
        handleClose={() => setIsWinModalOpen(false)}
        guesses={guesses}
        handleShare={() => {
          if (!detectMob()) {
            setIsWinModalOpen(false)
            setTimeout(() => {
              handleShareSuccess()
            }, 50)
          }
        }}
        timeRested={timeRested}
        stats={stats}
      />

      <WinModal
        isLost
        solution={solution}
        isOpen={isGameLost}
        handleClose={() => setIsGameLost(false)}
        guesses={guesses}
        handleShare={() => {
          if (!detectMob()) {
            setIsGameLost(false)
            setTimeout(() => {
              handleShareSuccess()
            }, 50)
          }
        }}
        timeRested={timeRested}
        stats={stats}
      />

      <RefreshModal
        isOpen={isRefreshModalOpen}
        handleClose={() => setIsRefreshModalOpen(false)}
        handleConfirm={() => {
          setIsRefreshModalOpen(false)
          clearGameStateToLocalStorage()
          saveGameStateToLocalStorage({
            guesses: [],
            solution: solution || '',
          })
          setGuesses([])
        }}
      />

      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />

      <AboutModal
        isOpen={isAboutModalOpen}
        handleClose={() => setIsAboutModalOpen(false)}
      />

      {/* Onboarding Tutorial */}
      <OnboardingTutorial isArabic={isArabic} />
    </div>
  )
}

export default App
