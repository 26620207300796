import { MiniCompletedRow } from './MiniCompletedRow'

type Props = {
  guesses: string[]
  solution: string
  isArabicKeyboard?: boolean
  noPadding?: boolean
  small?: boolean
}

export const MiniGrid = ({
  guesses,
  solution,
  isArabicKeyboard = false,
  noPadding,
  small,
}: Props) => {
  return (
    <div className={!noPadding ? 'pb-6' : ''}>
      {guesses.map((guess, i) => (
        <MiniCompletedRow
          key={i}
          guess={guess}
          solution={solution}
          isArabicKeyboard={isArabicKeyboard}
          small={small}
        />
      ))}
    </div>
  )
}
