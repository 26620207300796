import { getGuessStatuses } from '../../helpers/statuses'
import { MiniCell } from './MiniCell'

type Props = {
  guess: string
  solution: string
  isArabicKeyboard?: boolean
  small?: boolean
}

export const MiniCompletedRow = ({
  guess,
  solution,
  isArabicKeyboard = false,
  small = false,
}: Props) => {
  const statuses = getGuessStatuses(guess, solution)

  if (isArabicKeyboard) {
    return (
      <div className="flex justify-center mb-1">
        {guess
          .split('')
          .map((letter, i) => (
            <MiniCell key={i} status={statuses[i]} small={small} />
          ))
          .reverse()}
      </div>
    )
  }

  return (
    <div className="flex justify-center mb-1">
      {guess.split('').map((letter, i) => (
        <MiniCell key={i} status={statuses[i]} small={small} />
      ))}
    </div>
  )
}
