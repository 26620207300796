import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'

export const shareStatus = (
  guesses: string[],
  solution: string,
  isLost: boolean
) => {
  if (process.env.REACT_APP_LANG === 'Arabic') {
    const guessessLength = !!isLost ? 'X' : guesses.length
    navigator?.clipboard?.writeText(
      '#YawmiyaGame' +
        '\n' +
        'كلمة ' +
        solutionIndex +
        ' - ' +
        guessessLength +
        '/' +
        Number(process.env.REACT_APP_LENGTH_OF_LINES) +
        '\n\n' +
        generateEmojiGrid(guesses, solution) +
        '\n\n' +
        'جرب حظك من هنا' +
        '\n' +
        'https://yawmiya.co'
    )
  } else {
    const guessessLength = !!isLost ? 'X' : guesses.length
    navigator?.clipboard?.writeText(
      '#YawmiyaGame ' +
        '\n' +
        'Word ' +
        solutionIndex +
        ' - ' +
        guessessLength +
        '/' +
        Number(process.env.REACT_APP_LENGTH_OF_LINES) +
        '\n\n' +
        generateEmojiGrid(guesses, solution) +
        '\n\n' +
        'https://yawmiya.co'
    )
  }
}

export const shareMobileStatus = (
  guesses: string[],
  solution: string,
  isLost: boolean
) => {
  const guessessLength = !!isLost ? 'X' : guesses.length
  const slashLengthOfTries =
    '/' + Number(process.env.REACT_APP_LENGTH_OF_LINES) + '\n\n'

  if (navigator?.share) {
    const text =
      '#YawmiyaGame' +
      '_' +
      solutionIndex +
      '\n' +
      'كلمة اليوم ' +
      ' - ' +
      guessessLength +
      slashLengthOfTries +
      generateEmojiGrid(guesses, solution) +
      '\n\n' +
      'جرب حظك من هنا' +
      '\n' +
      'https://yawmiya.co'
    navigator
      ?.share({
        text,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error))
  } else {
    shareStatus(guesses, solution, isLost)
  }
}

// Helper to generate sharing text with consistent format
const generateShareText = (
  guesses: string[],
  solution: string,
  isLost: boolean
) => {
  const isArabic = process.env.REACT_APP_LANG === 'Arabic'
  const guessessLength = !!isLost ? 'X' : guesses.length
  const emojiGrid = generateEmojiGrid(guesses, solution)

  if (isArabic) {
    return (
      'كلمة ' +
      solutionIndex +
      ' - ' +
      guessessLength +
      '/' +
      Number(process.env.REACT_APP_LENGTH_OF_LINES) +
      '\n\n' +
      emojiGrid +
      '\n\n' +
      'جرب حظك من هنا' +
      '\n' +
      'https://yawmiya.co'
    )
  } else {
    return (
      '#YawmiyaGame ' +
      '\n' +
      'Word ' +
      solutionIndex +
      ' - ' +
      guessessLength +
      '/' +
      Number(process.env.REACT_APP_LENGTH_OF_LINES) +
      '\n\n' +
      emojiGrid +
      '\n\n' +
      'https://yawmiya.co'
    )
  }
}

export const shareToTwitter = (
  guesses: string[],
  solution: string,
  isLost: boolean
) => {
  const shareText = generateShareText(guesses, solution, isLost)

  // URL encode the text for the Twitter URL
  const encodedText = encodeURIComponent(shareText)

  // Construct the Twitter sharing URL
  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodedText}`

  // Open in a new window/tab
  window.open(twitterShareUrl, '_blank')
}

export const shareToWhatsApp = (
  guesses: string[],
  solution: string,
  isLost: boolean
) => {
  const shareText = generateShareText(guesses, solution, isLost)

  // URL encode the text for WhatsApp
  const encodedText = encodeURIComponent(shareText)

  // Construct the WhatsApp URL
  const whatsappUrl = `https://wa.me/?text=${encodedText}`

  // Open in a new window/tab
  window.open(whatsappUrl, '_blank')
}

export const generateEmojiGrid = (guesses: string[], solution: string) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess, solution)
      return guess
        .split('')
        .map((letter, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟢'
            case 'present':
              return '🔵'
            default:
              return '🔴'
          }
        })
        .join('')
    })
    .join('\n')
}
