import { Cell } from './Cell'

interface CurrentRowProps {
  guess: string
  isArabicKeyboard?: boolean
  cellSize?: {
    width: string
    height: string
    fontSize: string
    gap: string
  }
}

export const CurrentRow: React.FC<CurrentRowProps> = ({
  guess,
  isArabicKeyboard = false,
  cellSize,
}) => {
  const splitGuess = guess.split('')
  const emptyCells = Array.from(Array(5 - splitGuess.length))

  if (isArabicKeyboard) {
    return (
      <div className="flex justify-center">
        {emptyCells.map((_, i) => (
          <Cell key={i} cellSize={cellSize} isArabic={isArabicKeyboard} />
        ))}
        {splitGuess
          .map((letter, i) => (
            <Cell
              key={i}
              value={letter}
              cellSize={cellSize}
              isArabic={isArabicKeyboard}
            />
          ))
          .reverse()}
      </div>
    )
  }

  return (
    <div className="flex justify-center">
      {splitGuess.map((letter, i) => (
        <Cell key={i} value={letter} cellSize={cellSize} />
      ))}
      {emptyCells.map((_, i) => (
        <Cell key={i} cellSize={cellSize} />
      ))}
    </div>
  )
}
