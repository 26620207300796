import { WORDS } from '../constants/wordlist'
import { WORDS as WORDS_ARABIC } from '../constants/wordlist-arabic'
import { VALIDGUESSES } from '../constants/validGuesses'
import { VALIDGUESSES as VALIDGUESSES_ARABIC } from '../constants/validGuesses-arabic'

export const isWordInWordList = (word: string, listOfWords?: Array<string>) => {
  if (listOfWords && listOfWords?.length) {
    return listOfWords.includes(word.toLowerCase())
  }

  if (process.env.REACT_APP_LANG === 'Arabic') {
    return (
      WORDS_ARABIC.includes(word.toLowerCase()) ||
      VALIDGUESSES_ARABIC.includes(word.toLowerCase())
    )
  }
  return (
    WORDS.includes(word.toLowerCase()) ||
    VALIDGUESSES.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string, wordSolution?: string) => {
  if (wordSolution) {
    return wordSolution === word
  }
  return solution === word
}

export const getWordOfDay = (wordSolution?: string) => {
  if (wordSolution) {
    return {
      solution: wordSolution,
    }
  }

  if (process.env.REACT_APP_LANG === 'Arabic') {
    return {
      solution: WORDS_ARABIC[3],
    }
  }

  const epochMs = 1644031652401
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs) / msInDay)

  return {
    solution: WORDS[index].toUpperCase(),
  }
}

export const getIndexWordOfDay = () => {
  const epochMs = new Date('5 February 2022')
  const now = Date.now()
  const msInDay = 86400000
  const index = Math.floor((now - epochMs.getTime()) / msInDay)

  return {
    solutionIndex: index,
  }
}

export const { solution } = getWordOfDay()
export const { solutionIndex } = getIndexWordOfDay()
